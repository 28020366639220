<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col md="4" class="my-1">
            <h2><b>Stock Warranty</b></h2>
          </b-col>
          <b-col md="8" class="my-1">
            <b-form inline class="d-flex justify-content-end">
              <b-input-group class="input-group-merge" style="width: 40%">
                <b-form-input
                  id="basic-password1"
                  type="text"
                  placeholder="พิมพ์ Serial No. ที่ต้องการค้นหาและกด Enter"
                  v-model="searchSerial"
                  @keydown.enter.prevent="onSearch"
                />
                <b-input-group-append is-text @click="onSearch" title="คลิกเพื่อค้นหา Serial">
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-input-group-append>
              </b-input-group>   
              <b-button                
                variant="outline-primary"
                class="ml-1 shadow"   
                title="คลิกเพื่อ Reload รายการ Stock Warranty"
                @click="getWarrantyStock"                        
                ><feather-icon icon="RefreshCwIcon" size="14"
              />  </b-button>

              
              <b-button variant="outline-primary" class="ml-1 shadow" v-b-modal.search-more-modal
                >Search more <feather-icon icon="FilterIcon" size="14"
              /></b-button>



              <b-button
                v-if="warrantyPermission=='create' || warrantyPermission=='edit'"
                variant="primary"
                class="ml-1 shadow"   
                @click="onOpenCreateModal"                        
                ><feather-icon icon="PlusCircleIcon" size="14"
              /> Add </b-button>
            </b-form>
          </b-col>

          <b-col cols="12">
            <b-table
              style="font-size: 12px"
              class="shadow"
              small
              striped
              bordered
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :busy="isLoading"
              show-empty
            >
            <template #empty="scope">
                  <center>ค้นหาไม่พบ</center>
              </template>
            <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading...</strong>
                </div>
              </template>

            <template #cell(no)="row">
                <center>{{ row.index + 1 }}</center>
              </template>

            <template #cell(startDate)="row">
                <center>{{ showDate(row.item.startDate, "date") }}</center>                
            </template>
            <template #cell(endDate)="row">
                <center>{{ showDate(row.item.endDate, "date") }}</center>                
            </template>

              <template #cell(action)="row" v-if="warrantyPermission=='edit'">
                <center>
                  <b-button variant="outline-secondary" class="mr-1" size="sm" pill @click="onSelectedItem(row.item)" v-b-modal.add-stock-warranty-modal  >
                    <feather-icon                    
                    icon="EditIcon"
                    class="cursor-pointer text-secondary"
                  />
                </b-button>
                  <b-button variant="danger" size="sm" pill @click="onDeleteWarrantyStock(row.item.id)">
                    <feather-icon                    
                    icon="TrashIcon"
                    class="cursor-pointer text-white"
                  />
                  </b-button>
                </center>
              </template>
            </b-table>
          </b-col>
          <b-col cols="2">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>

          <b-col cols="10" class="d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              variant="secondary"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <AddStockWarrantyModal  :getWarrantyStock="getWarrantyStock" :itemSelected="itemSelected" />
    <SearchMoreModal :getWarrantyStock="getWarrantyStock"/>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BForm,
  BSpinner
} from "bootstrap-vue";
import vSelect from "vue-select";
import moment from "moment"

import Field from "./field";

//SERVICES
import WarrantyStockService from "@/services/stock/warranty"

import AddStockWarrantyModal from "./component/modal/addStock.vue"
import SearchMoreModal from "./component/modal/searchMoreModal.vue"

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BSpinner,
    BForm,
    vSelect,
    AddStockWarrantyModal,
    SearchMoreModal
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 50, 100, 500, 1000, 2000, 10000],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: Field,
      items: [],
      statusSelected: { title: "เปิดใช้งาน" },
      statusOption: [
        { title: "เปิดใช้งาน" },
        { title: "เครื่องเสีย" },
      ],
      isLoading:false,
      itemSelected:{},
      searchSerial:""
   
    
    };
  },

  async created(){
    // if(this.warrantyPermission !== 'edit'){
    //   const field = Field.filter(f=>f.key!=="action")     
    //   this.fields = field
    // }   

    await this.getWarrantyStock()
  },
  computed :{
    warrantyPermission () {
      const permission = this.$store.getters["user/getPermissionPage"]("STK004")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission       
    }
  },
  mounted() {    
    
  },
  methods: {
    async onSearch(){
      if(!this.searchSerial){
        return
      }
      await this.getWarrantyStock(`serial=${this.searchSerial}`) 
    }, 
    async getWarrantyStock(queryString=""){
      this.isLoading = true
      const result = await WarrantyStockService.GetWarrantyStock(queryString)
      this.items = result.data.data
      this.totalRows = this.items.length;
      this.isLoading = false
    },
    async onDeleteWarrantyStock(id){
      const isConfirm = confirm("ต้องการลบรายการนี้หรือไม่ ?")
      if(!isConfirm) return false
    
      this.isLoading = true
      const result = await WarrantyStockService.DeleteWarrantyStock(id)
      await this.getWarrantyStock()
      this.isLoading = false
    },
    showDate(date, type="") {
      if (type == "date") {
        return moment(date).format("DD-MM-yyyy");
      }

      if(type=="original"){
        return moment(date).subtract(7, 'h').format("DD-MM-yyyy HH:mm:ss");
      }

      if(type=="subtract"){
        return moment(date).subtract(7, 'h')
      }

      return moment(date).format("DD-MM-yyyy HH:mm:ss");
    },
    onSelectedItem(item){
      this.itemSelected = item
    },
    onOpenCreateModal(){
      this.itemSelected = {}
      this.$bvModal.show("add-stock-warranty-modal")
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
.table-hover tbody tr:hover {
  background-color: aqua !important;
}
</style>
