export default [
  {
    key: "no",
    label: "#",
    thStyle:{color:"white", textAlign: "center"}
  },

  { key: "branchName", label: "Customer", sortable: true , thStyle:{color:"white",width: "15% !important", textAlign: "center"}},
  
  { key: "hwTypeName", label: "Type", sortable: true , thStyle:{color:"white",width: "10% !important", textAlign: "center"}},
  { key: "hwBrandName", label: "Brand", sortable: true ,thStyle:{color:"white",width: "10% !important", textAlign: "center",}},
  { key: "hwModelName", label: "Model", sortable: true ,thStyle:{color:"white",width: "10% !important", textAlign: "center",}},
  {
    key: "serial",
    label: "Serial No.",
    thStyle:{color:"white",width: "12% !important", textAlign: "center"}
  },
  
  { key: "startDate", label: "Start", sortable: true , thStyle:{color:"white",width: "8% !important", textAlign: "center"}},
  { key: "endDate", label: "End", sortable: false , thStyle:{color:"white",width: "8% !important", textAlign: "center"}},
  {
    key: "remark",
    label: "Remark",
    sortable: false,
    thStyle: {  textAlign: "center" ,color:"white"},
  },
  {
    key: "action",
    label: "จัดการ",
    sortable: false,
    thStyle: { width: "12% !important", textAlign: "center", color:"white" },
  },
];
