<template>
  <div>
    <b-modal ref="search-more-modal" id="search-more-modal" title="ค้นหา" size="lg" hide-footer no-close-on-backdrop>
      <b-card-text>
        <b class="text-primary">ค้นหาด้วยข้อมูลพื้นฐาน</b>
        <b-row class="mt-1">
          <b-col md="6" class="">
            <label>Serial</label>           
              <b-form-input placeholder="Serial" size="sm" v-model="serial" />
          </b-col>
        </b-row>
        <!--- END (Serial) --->

        <!--- START (วันที่) --->
        <b-row class="mt-1">
          <b-col md="4" class="">
            <label>โหมดค้นหาวันที่</label>
            <v-select
              id="brand"
              v-model="dateModeSelected"
              label="title"
              :options="dateModeOption"
              class="select-size-sm"
              :clearable="false"
            />
          </b-col>

          <b-col md="4" class="">
            <label>Start Date</label>
            <b-form-datepicker
              id="example-datepicker"
              locale="th-TH"
              v-model="dateStart"
              show-decade-nav
              class="mb-1"
              size="sm"
            />
          </b-col>
          <b-col md="4" class="">
            <label>End Date</label>
            <b-form-datepicker
              id="example-datepicker2"
              locale="th-TH"
              v-model="dateEnd"
              show-decade-nav
              class="mb-1"
              size="sm"
            />
          </b-col>
        </b-row>
        <!--- END (วันที่) --->


        <b class="text-primary">ค้นหาด้วยอุปกรณ์</b>
        <!--- START (ประเภทอุปกรณ์) --->
        <b-row class="mt-1 mb-2">
          <b-col md="4" class="">
            <label>Type</label>
            <v-select
              id="brand"
              v-model="hwTypeSelected"
              label="hwTypeName"
              :options="hwTypeOption"
              class="select-size-sm"
              :clearable="false"
              @input="onChangeHwType"
            />
          
          </b-col>
          <b-col md="4" v-if="hwTypeSelected.id!==0">
            <label>Brand</label>
            <v-select
              id="brand"
              v-model="hwBrandSelected"
              label="hwBrandName"
              :options="hwBrandOption"
              class="select-size-sm"
              :clearable="false"
              @input="onChangeHwBrand"
            />
          </b-col>
          <b-col md="4" v-if="hwBrandSelected.id!==0 && hwTypeSelected.id!==0">
            <label>Model</label>
            <v-select
              id="brand"
              v-model="hwModelSelected"
              label="hwModelName"
              :options="hwModelOption"
              class="select-size-sm"
              :clearable="false"
            />
          </b-col>
        </b-row>
        <!--- END (ประเภทอุปกรณ์) --->        

       
        
        <b class="text-primary">ค้นหาด้วยลูกค้า</b>
        <!--- START (ลูกค้า) --->
        <b-row>
          <b-col cols="6">
            <label>Customer</label>
            <v-select
              id="brand"
              v-model="subCompanySelected"
              label="subCompanyName"
              :options="subCompanyOption"
              class="select-size-sm"
              :clearable="false"
              @input="onChangeCustomerSubCompany"
            />
          </b-col>
          <b-col cols="6" v-if="subCompanySelected.id!==0">
            <label>Branch</label>
            <v-select
              id="brand"
              v-model="branchSelected"
              label="branchName"
              :options="branchOption"
              class="select-size-sm"
              :clearable="false"
            />
          </b-col>          
        </b-row>
        <!--- END (ลูกค้า) --->    


        <!--- START (BTN SUBMIT) --->
        <b-row class="mt-2">
          <b-col md="12" class="d-flex justify-content-end">
            <b-button variant="outline-primary" class="mr-1" size="sm" @click="onClear">ล้างการค้นหา</b-button>
            <b-button variant="primary" size="sm" @click="onSearch">ค้นหา</b-button>
          </b-col>
        </b-row>
        <!--- END (BTN SUBMIT) --->
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  VBModal,
  BCardText,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BButton
} from "bootstrap-vue";
import vSelect from "vue-select";

import UserService from "@/services/user";
import CustomerService from "@/services/setting/customer";
import HardwareService from "@/services/setting/hardware";

export default {
  props:['getWarrantyStock'],
  components: {
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    vSelect,
    BFormDatepicker,
    BButton
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      serial:"",
      movementTypeSelected: { title: "เลือกทั้งหมด", value: "All" },   
      dateModeSelected:{ title: "วันเริ่มต้น", value: "start" },
      movementTypeOption: [
        { title: "เลือกทั้งหมด", value: "All" },
        { title: "รอการตรวจสอบ", value: "Wait" },
        { title: "ซ่อมเรียบร้อย", value: "Complete" },
        { title: "เสีย", value: "Bad" },
      ],      
      dateModeOption: [
        { title: "วันเริ่มต้น", value: "start" },
        { title: "วันสิ้นสุด", value: "end" }, 
      ],      
      hwTypeSelected: {hwTypeName:"เลือกทั้งหมด", id:0},
      hwBrandSelected: {hwBrandName:"เลือกทั้งหมด", id:0},
      hwModelSelected: {hwModelName:"เลือกทั้งหมด", id:0},
      hwTypeOption: [],
      hwBrandOption: [],
      hwModelOption: [],
      ownerSelected: {ownerName:"เลือกทั้งหมด", id:0},
      ownerOption: [],
      companySelected: {},
      companyOption: [],
      subCompanySelected: {subCompanyName:"เลือกทั้งหมด", id:0},
      subCompanyOption: [],  
      branchOption: [],
      branchSelected: {branchName:"เลือกทั้งหมด", id:0},   
      dateStart: "",
      dateEnd: "",
    };
  },
  created(){
    this.getHwType()
    this.getCustomerSubCompany()
  },
  methods:{
    onSearch(){
      let queryString = []
      if(this.serial){
        queryString.push(`serial=${this.serial}`)
      }

      if(this.dateModeSelected){
        queryString.push(`dateMode=${this.dateModeSelected.value}`)
      }


      if(this.dateStart&&this.dateEnd){
        queryString.push(`dateStart=${this.dateStart}&dateEnd=${this.dateEnd}`)
      }
     
      if(this.subCompanySelected.id!==0){
        queryString.push(`subCompanyId=${this.subCompanySelected.id}`)
      }

      if(this.branchSelected.id && this.subCompanySelected.id!==0){
        queryString.push(`branchId=${this.branchSelected.id}`)
      }

      if(this.hwTypeSelected.id && this.hwTypeSelected.id!==0){
        queryString.push(`hwTypeId=${this.hwTypeSelected.id}`)
      }

      if(this.hwBrandSelected.id && this.hwTypeSelected.id!==0){
        queryString.push(`hwBrandId=${this.hwBrandSelected.id}`)
      }

      if(this.hwModelSelected.id && this.hwTypeSelected.id!==0){
        queryString.push(`hwModelId=${this.hwModelSelected.id}`)
      }

      const joined = queryString.join("&")
      this.getWarrantyStock(joined)
      this.hideModal()
    }, 

    onClear(){
      this.serial = ""
      this.dateStart = ""
      this.dateEnd = ""
      this.dateModeSelected ={ title: "วันเริ่มต้น", value: "start" }
      this.movementTypeSelected = { title: "เลือกทั้งหมด", value: "All" }
      this.hwTypeSelected = {hwTypeName:"เลือกทั้งหมด", id:0}
      this.ownerSelected= {ownerName:"เลือกทั้งหมด", id:0}
    },

    async getCustomerSubCompany() {
      const result = await CustomerService.GetCustomerSubCompanyListAll("mode=warranty");      
      this.subCompanyOption = [{subCompanyName:"เลือกทั้งหมด", id:0},...result.data.data];
      if (result.data.data.length > 0) {        
        this.subCompanySelected = {subCompanyName:"เลือกทั้งหมด", id:0};         
      }else{
        this.subCompanySelected = {}
      }
    },

    async getCustomerBranch(subCompanyObj) {
      const result = await CustomerService.GetCustomerBranchList(
        subCompanyObj.id,{}, "mode=warranty"
      );
   
      this.branchOption = [{branchName:"เลือกทั้งหมด", id:0},...result.data.data];
      if (result.data.data.length > 0) {      
        this.branchSelected = {branchName:"เลือกทั้งหมด", id:0};        
      }else{
        this.branchSelected = {}
      }
    },    
    onChangeCustomerSubCompany(item) {
      this.getCustomerBranch(item);
    },

    async getHwType() {
      const result = await HardwareService.GetHwTypeList("warranty");
      this.hwTypeOption.push({hwTypeName:"เลือกทั้งหมด", id:0}) 
      this.hwTypeOption = [...this.hwTypeOption,...result.data];

      if (result.data.length > 0) {
        let item = result.data[0];
        await this.onChangeHwType(item);
      }else{
        this.hwTypeSelected = {}
      }
    },

    async getHwBrand(typeId) {  
      const result = await HardwareService.GetHwBrandList(typeId, "warranty"); 
      this.hwBrandOption = [{hwBrandName:"เลือกทั้งหมด", id:0}, ...result.data];      

      if (result.data.length > 0) {
        let item = this.hwBrandOption[0];    
        this.hwBrandSelected = item;
        //await this.onChangeHwBrand(item);
      }else{
        this.hwBrandSelected = {}
      }
    },

    async getHwModel(brandId) {
      const result = await HardwareService.GetHwModelList(brandId, "warranty");
      this.hwModelOption = [{hwModelName:"เลือกทั้งหมด", id:0},...result.data];
      if (result.data.length > 0) { 
        let item = this.hwModelOption[0];         
          this.hwModelSelected = item;        
      }else{
        this.hwModelSelected = {}
      }
    },
    async onChangeHwType(item) {
      await this.getHwBrand(item.id);
    },

    async onChangeHwBrand(item) {
      await this.getHwModel(item.id);
    },
    hideModal() {
      this.$refs['search-more-modal'].hide()
    },
  }
};
</script>

<style>
@import "./style.css";
</style>
