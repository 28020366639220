<template>
  <div>
    <b-modal
      ref="add-stock-warranty-modal"
      id="add-stock-warranty-modal"
      :title="modalTitle"
      size="lg"
      hide-footer
      no-close-on-backdrop
    >
      <b-card-text>
        <!--- START (Topic ร้าน/สาขา) --->
        <b-row>
          <b-col>
            <span><b>Customer/Branch </b></span>
          </b-col>
        </b-row>
        <!--- END (Topic ร้าน/สาขา) --->
        <b-overlay
          :show="isLoading"
          rounded="lg"
          opacity="0.55"
          blur="3px"
          variant="transparent"
          fixed
        >
          <!--- START (ลูกค้า) --->
          <b-row class="mt-1">
            <b-col cols="6">
              <label>Customer</label>
              <v-select
                id="brand"
                v-model="subCompanySelected"
                label="subCompanyName"
                :options="subCompanyOption"
                :clearable="false"
                class="select-size-sm"
                @input="onChangeCustomerSubCompany"
              />
            </b-col>
            <b-col cols="6">
              <label>Branch</label>
              <v-select
                id="brand"
                v-model="branchSelected"
                label="branchName"
                :options="branchOption"
                :clearable="false"
                class="select-size-sm"
              />
            </b-col>
          </b-row>
          <!--- END (ลูกค้า) --->
        </b-overlay>

        <!--- START (Topic ข้อมูลเครื่อง) --->
        <b-row class="mt-3">
          <b-col>
            <span><b>ข้อมูลเครื่อง</b></span>
          </b-col>
        </b-row>
        <!--- END (Topic ข้อมูลเครื่อง) --->

        <b-overlay
          :show="isLoading"
          rounded="lg"
          opacity="0.55"
          blur="3px"
          variant="transparent"
          fixed
        >
          <!--- START (ประเภทอุปกรณ์) --->
          <b-row class="mt-1">
            <b-col md="4" class="">
              <label>Type</label>
              <v-select
                id="brand"
                v-model="hwTypeSelected"
                label="hwTypeName"
                :options="hwTypeOption"
                class="select-size-sm"
                :clearable="false"
                @input="onChangeHwType"
              />
            </b-col>
            <b-col md="4" class="">
              <label>Brand</label>
              <v-select
                id="brand"
                v-model="hwBrandSelected"
                label="hwBrandName"
                :options="hwBrandOption"
                class="select-size-sm"
                :clearable="false"
                @input="onChangeHwBrand"
              />
            </b-col>
            <b-col md="4" class="">
              <label>Model</label>
              <v-select
                id="brand"
                v-model="hwModelSelected"
                label="hwModelName"
                :options="hwModelOption"
                class="select-size-sm"
                :clearable="false"
              />
            </b-col>
          </b-row>

          <!--- END (ประเภทอุปกรณ์) --->
        </b-overlay>

        <!--- START (Serial) --->
        <b-row class="mt-1">
          <b-col md="6" class="">
            <label>Serial <span class="text-danger">*</span></label>
            <b-input-group>
              <b-form-input placeholder="Serial" size="sm" v-model="serial" />
              <b-input-group-append v-if="mode == 'create'">
                <b-button
                  variant="primary"
                  size="sm"
                  @click="onPushSerialToList"                 
                >
                  <feather-icon icon="PlusCircleIcon" size="14" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <b-list-group class="mt-2" v-for="(item, index) in serials" :key="item.serial">
              <b-input-group >
              <b-form-input placeholder="Serial" size="sm" v-model="item.serial" />
              <b-input-group-append v-if="mode == 'create'">
                <b-button
                  variant="danger"
                  size="sm"
                  @click="onDeleteSerialInList(index)"                 
                >
                  <feather-icon icon="TrashIcon" size="14" />
                </b-button>
              </b-input-group-append>
            </b-input-group>           
            </b-list-group>
          </b-col>
        </b-row>
        <!--- END (Serial) --->

        <b-row class="mt-2" v-if="serialAlreadyHwTypeList.length>0">
          <b-col>
            <b-alert show variant="danger" class="p-1" style="font-size:0.87em">
              <b>Serial ที่ซ้ำกับประเภทอื่น</b>
              <ul>
                <li v-for="item in serialAlreadyHwTypeList" :key="item.serial">{{ item.serial }} (ประเภท {{ item.hwTypeName }}) </li>
              </ul>              
            </b-alert>
          </b-col>
        </b-row>

        <b-row class="mt-2" v-else>
          <b-col v-if="serialAlreadyList.length>0">
            <b-alert show variant="danger" class="p-1" style="font-size:0.87em">
              <b>Serial ที่ซ้ำ</b>
              <ul>
                <li v-for="item in serialAlreadyList" :key="item.serial">{{ item.serial }}  </li>
              </ul>              
            </b-alert>
          </b-col>
        </b-row>



        <!--- START (วันที่) --->
        <b-row class="mt-1">
          <b-col md="4" class="">
            <label>Start Date <span class="text-danger">*</span> </label>
            <b-form-datepicker
              id="example-datepicker"
              locale="th-TH"
              v-model="startDate"
              show-decade-nav
              class="mb-1"
              size="sm"
            />
          </b-col>
          <b-col md="4" class="">
            <label>End Date <span class="text-danger">*</span></label>
            <b-form-datepicker
              id="example-datepicker2"
              locale="th-TH"
              v-model="endDate"
              show-decade-nav
              class="mb-1"
              size="sm"
            />
          </b-col>
          <b-col md="4" class="">
            <label>เลือกจำนวนเดือนที่รับประกัน</label>
            <select class="form-control" @change="selectWarrantyAmount" :disabled="!startDate" style="height:32px">
              <option value="0">เลือกจำนวนเดือน</option>
              <option value="3">3 เดือน</option>
              <option value="6">6 เดือน</option>
              <option value="12">12 เดือน (1 ปี)</option>
              <option value="24">24 เดือน (2 ปี)</option>
              <option value="36">36 เดือน (3 ปี)</option>
              <option value="48">48 เดือน (4 ปี)</option>
              <option value="60">60 เดือน (5 ปี)</option>
            </select>
          </b-col>
        </b-row>
        <!--- END (วันที่) --->

        <!--- START (รายละเอียด) --->
        <b-row class="mt-1">
          <b-col md="12" class="">
            <label>Remark</label>
            <b-form-textarea
              id="textarea-default"
              placeholder="Remark"
              rows="2"
              size="sm"
              v-model="remark"
            />
          </b-col>
        </b-row>
        <!--- END (รายละเอียด) --->

        <!--- START (BTN SUBMIT) --->
        <b-row class="mt-1">
          <b-col md="12" class="d-flex justify-content-end">
            <b-button
              variant="primary"
              class="btn-sm"
              :disabled="isLoading "
              @click="onSave"
              >บันทึก</b-button
            >
          </b-col>
        </b-row>

        
        <!--- END (BTN SUBMIT) --->
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  VBModal,
  BCardText,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BButton,
  BFormDatepicker,
  BListGroup,
  BListGroupItem,
  BOverlay,
  BAlert 
} from "bootstrap-vue";
import vSelect from "vue-select";

import HardwareService from "@/services/setting/hardware";
import CustomerService from "@/services/setting/customer";
import WarrantyStockService from "@/services/stock/warranty";
import moment from "moment"

export default {
  props: ["getWarrantyStock", "itemSelected"],
  components: {
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    vSelect,
    BInputGroupAppend,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BListGroup,
    BListGroupItem,
    BOverlay,
    BAlert 
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      hwTypeSelected: {},
      hwBrandSelected: {},
      hwModelSelected: {},
      hwTypeOption: [],
      hwBrandOption: [],
      hwModelOption: [],
      ownerSelected: {},
      ownerOption: [],
      companySelected: {},
      companyOption: [],
      subCompanySelected: {},
      subCompanyOption: [],
      branchOption: [],
      branchSelected: {},
      startDate: "",
      endDate: "",
      serial: "",
      remark: "",
      serials: [],
      isLoading: false,
      mode: "create",
      modalTitle: "เพิ่มรายการ",
      serialAlreadyList:[],
      serialAlreadyHwTypeList:[]
    };
  },

  async created() {
    this.serials = [];
    this.serialAlreadyList = []
    this.serialAlreadyHwTypeList = []
    
    this.isLoading = true;
    await Promise.all([this.getCustomerSubCompany(), this.getHwType()]);

    this.isLoading = false;
  },
  computed:{
    isInvalid(){
      if(this.mode == "create" && this.serials.length==0){
        return true
      }

      if(this.mode == "edit" && !this.serial){
        return true
      }

      if(this.startDate=="" || this.endDate==""){
        return true
      }
    }
  },
  watch: {
    async itemSelected(data) {
      this.serials = [];
      this.serialAlreadyList = []
      this.serialAlreadyHwTypeList = []
      if (data.id) {
        this.modalTitle = "แก้ไขรายการ";
        this.mode = "edit";
        this.remark = data.remark;
        this.startDate = data.startDate;
        this.endDate = data.endDate;
        //this.serials.push({serial:data.serial})
        this.serial = data.serial;
        this.isLoading = true;
        await Promise.all([this.getCustomerSubCompany(), this.getHwType()]);
        this.isLoading = false;
      } else {
        this.modalTitle = "เพิ่มรายการ";
        this.mode = "create";
        this.remark = "";
        this.startDate = "";
        this.endDate = "";
        this.serial = "";
        this.hwTypeSelected= {}
        this.hwBrandSelected= {}
        this.hwModelSelected= {}
        this.subCompanySelected= {}
        this.branchSelected= {}
      }
    },
  },
  methods: {
    validateForm(){
      if(this.mode == "create" && this.serials.length==0 && !this.serial){
        alert("กรุณากรอก Serial")
        return true
      }

      if(this.mode == "edit" && !this.serial){
        alert("กรุณากรอก Serial")
        return true
      }

      if(Object.keys(this.branchSelected).length==0){
        alert("กรุณาเลือกสาขา")
        return true
      }


      if(this.startDate=="" || this.endDate==""){
        alert("กรุณาเลือกวันเริ่มต้นประกัน และวันสิ้นสุดประกัน")
        return true
      }
    },


    async checkAlreadySerials(){
      let serialList=[]
      for (let index = 0; index < this.serials.length; index++) {
        const data = this.serials[index];
        serialList.push(data.serial)        
      }

      if(this.serial){
        serialList.push(this.serial)  
      }

      const result = await WarrantyStockService.CheckAlreadySerials({serials:serialList})     
      if(result.data){
        this.serialAlreadyList = result.data        
      }

      return this.serialAlreadyList.length > 0 ? true : false

    },
    checkAlreadySerialsWithOtherHwtype(){
      const serialWarningList = this.serialAlreadyList
      let duplicates = []
      for (let index = 0; index < serialWarningList.length; index++) {
        const item = serialWarningList[index];
        if(item.hwTypeId !== this.hwTypeSelected.id ){
          duplicates.push(item)
          this.serialAlreadyList.splice(index, 1);
        }        
      }

      this.serialAlreadyHwTypeList = duplicates
      return this.serialAlreadyHwTypeList.length > 0 ? true : false
      
    },

    confirmDialog(msg) {
      return new Promise(function (resolve, reject) {
        setTimeout(() => {
          let confirmed = window.confirm(msg);
          return confirmed ? resolve(true) : reject(false);
        }, 10);
        
      });
    },

    async onSave() {
      const invalid = this.validateForm()
      if(invalid) return;               

      if (this.mode == "create") {
        const filtered = this.serials.filter(f=>f.serial==this.serial);
        if(filtered.length>0 && this.serial){
          alert(`กรุณาตรวจสอบ Serial : ${this.serial} เนื่องจากได้ทำการเพิ่มในรายการแล้ว`)
          return
        }
       

        const hasAlreadySerial = await this.checkAlreadySerials()
        const isDuplicateOtherHwtype = this.checkAlreadySerialsWithOtherHwtype()

        if(isDuplicateOtherHwtype) {
          await this.confirmDialog("ต้องการบันทึก Serial ที่มีการซ้ำกับประเภทอื่นหรือไม่ ?")         
        }


        if(hasAlreadySerial&&this.serialAlreadyList.length>0) return       

        await this.insertWarranty();
      } else {
        await this.editWarranty();
      }
    },
    async insertWarranty() {  
      let serials = []    
      const filtered = this.serials.filter(s=>s.serial)  
      serials = filtered
      
      if(this.serial){
        serials.push({serial:this.serial})
      }      

      const obj = {
        serials: serials,
        startDate: this.startDate,
        endDate: this.endDate,
        remark: this.remark,
        hwTypeId: this.hwTypeSelected.id,
        hwBrandId: this.hwBrandSelected.id,
        hwModelId: this.hwModelSelected.id,
        ownerId: this.ownerSelected.id,
        companyId: this.companySelected.id,
        subCompanyId: this.subCompanySelected.id,
        branchId: this.branchSelected.id,
      };
      
      this.isLoading = true;
      const result = await WarrantyStockService.InsertWarrantyStock(obj);
      await this.getWarrantyStock();
      this.isLoading = false;
      this.hideModal();
    },
    async editWarranty() {     
      let serial = this.serial    
      const obj = {
        serial: serial,
        startDate: this.startDate,
        endDate: this.endDate,
        remark: this.remark,
        hwTypeId: this.hwTypeSelected.id,
        hwBrandId: this.hwBrandSelected.id,
        hwModelId: this.hwModelSelected.id,
        ownerId: this.ownerSelected.id,
        companyId: this.companySelected.id,
        subCompanyId: this.subCompanySelected.id,
        branchId: this.branchSelected.id,
      };
     
      this.isLoading = true;
      const result = await WarrantyStockService.UpdateWarrantyStockById(
        obj,
        this.itemSelected.id
      );
      await this.getWarrantyStock();
      this.isLoading = false;
      this.hideModal();
    },
    async getHwType() {
      const result = await HardwareService.GetHwTypeList("warranty");
      this.hwTypeOption = result.data;

      if (result.data.length > 0) {
        let item = {};
        if (this.mode == "create") {
          item = result.data[0];
        } else {
          item = this.hwTypeOption.find(
            (f) => f.id == this.itemSelected.hwTypeId
          );
          
          if (!item) {
            item = result.data[0];
          }       
          
        }
        this.hwTypeSelected = item;
        await this.onChangeHwType(item);
      } else {
        this.hwTypeSelected = {};
      }
    },

    async getHwBrand(typeId) {     
      const result = await HardwareService.GetHwBrandList(typeId, "warranty");
      this.hwBrandOption = result.data;

      if (result.data.length > 0) {
        let item = {};
        if (this.mode == "create") {
          item = result.data[0];
        } else {
          item = this.hwBrandOption.find(
            (f) => f.id == this.itemSelected.hwBrandId
          );

          if (!item) {
            item = result.data[0];
          }
        }

        this.hwBrandSelected = item;
        await this.onChangeHwBrand(item);
      } else {
        this.hwBrandSelected = {};
      }
    },

    async getHwModel(brandId) {
      const result = await HardwareService.GetHwModelList(brandId, "warranty");
      this.hwModelOption = result.data;

      if (result.data.length > 0) {
        if (this.mode == "create") {
          this.hwModelSelected = result.data[0];
        } else {
          let findHwModel = this.hwModelOption.find(
            (f) => f.id == this.itemSelected.hwModelId
          );

          if (!findHwModel) {
            findHwModel = result.data[0];
          }
          this.hwModelSelected = findHwModel;
        }
      } else {
        this.hwModelSelected = {};
      }
    },

    async getCustomerSubCompany() {
      const result = await CustomerService.GetCustomerSubCompanyListAll("mode=warranty");  
      this.subCompanyOption = result.data.data;

      if (result.data.data.length > 0) {
        let item = {};
        if (this.mode == "create") {
          item = result.data.data[0];
        } else {
          item = this.subCompanyOption.find(
            (f) => f.id == this.itemSelected.subCompanyId
          );

          if (!item) {
            item = result.data.data[0];
          }
        }
        this.subCompanySelected = item;
        this.onChangeCustomerSubCompany(item);
      } else {
        this.subCompanySelected = {};
      }
    },

    async getCustomerBranch(subCompanyObj) {
      const result = await CustomerService.GetCustomerBranchList(
        subCompanyObj.id,{}, "mode=warranty"
      );
      this.branchOption = result.data.data;
      if (result.data.data.length > 0) {
        if (this.mode == "create") {
          this.branchSelected = result.data.data[0];
        } else {
          let findBranch = this.branchOption.find(
            (f) => f.id == this.itemSelected.branchId
          );

          if (!findBranch) {
            findBranch = result.data.data[0];
          }

          this.branchSelected = findBranch;
        }
      } else {
        this.branchSelected = {};
      }
    },

    async onChangeHwType(item) {
      await this.getHwBrand(item.id);
    },

    async onChangeHwBrand(item) {
      await this.getHwModel(item.id);
    },

    onChangeCustomerOwner(item) {
      this.getCustomerCompany(item);
    },
    onChangeCustomerCompany(item) {
      this.getCustomerSubCompany(item);
    },
    onChangeCustomerSubCompany(item) {
      this.getCustomerBranch(item);
    },
    onPushSerialToList() {
     // if (!this.serial) return;
      const filtered = this.serials.filter(f=>f.serial==this.serial);
      if(filtered.length>0 && this.serial){
        alert("Serial นี้ได้ทำการเพิ่มแล้ว")
        return
      }
      this.serials.push({ serial: this.serial });
      this.serial = "";
    },
    onDeleteSerialInList(index) {
      this.serials.splice(index, 1);
    },
    selectWarrantyAmount(e){
      let monthAmount = e.target.value
      if(monthAmount==0) {
        this.endDate = ""
        return;
      }

      const currentDate = moment(this.startDate);
      const futureMonth = moment(currentDate).add(monthAmount, 'M').subtract(1, 'day').format("YYYY-MM-DD");
      this.endDate = futureMonth
    
    },

    hideModal() {
      this.$refs["add-stock-warranty-modal"].hide();
    },
  },
};
</script>

<style>
@import "./style.css";
</style>
